<template>
  <div></div>
</template>
<script>
export default {
  name: 'HomeSellers',
  data() {
    return {}
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
    },
  },
}
</script>
